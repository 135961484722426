<template>
  <v-card
    elevation="0"
    rounded="xl"
    style="width: 100%;"
  >
    <Scrollbar
      :thin="true"
      autoHide="leave"
      :style="`height: ${rootHeight}px`"
    >
      <v-container>
        <v-row
          class="px-3 py-1"
        >
          <v-col
            v-for="member in members"
            cols="12"
            sm="6"
          >
            <v-card
              class="px-3 py-1"
              rounded="xl"
              :style="`display: flex; align-items: center; cursor: pointer; background-color: ${memberCardBackgroundColor};`"
              outlined
            >
              <ProfileAvatar
                :avatar="member.profile.avatar"
              /> 
              <div class="member-controllers-container">
                <div>
                  {{ member.profile.name }}  
                </div>

                <div>
                  <v-chip
                    color="primary"
                    text-color="white"
                    style="border-radius: 30px;"
                    class="mr-1"
                    small
                  >
                    {{ $t(`community.permissions.${member.role}`) }}
                  </v-chip>
                  <MenuActions
                    v-if="!!membership && membership.role !== 'member' && member.role !== 'owner'"
                    :member="member"
                    :userMember="membership"
                    :removeMember="removeMember"
                    :activateMember="activateMember"
                    :setMemberRole="setMemberRole"
                  />
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </Scrollbar>
  </v-card>
</template>

<script>
  import ProfileAvatar from '@components/Hall/ProfileAvatar'
  import Member from '@api/member'
  import { mapGetters } from 'vuex'
  import MenuActions from '@components/Community/Member/MenuActions'
  import Scrollbar from '@components/App/Scrollbar'

  export default {
    props: {
      members: Array,
      membership: Object,
      removeMember: Function,
      activateMember: Function,
      setMemberRole: Function
    },

    components: {
      ProfileAvatar,
      MenuActions,
      Scrollbar
    },

    data() {
      return {
        memberService: null,  
      }
    },

    created() {
      this.memberService = Member(this.user.id)
    },

    computed: {
      ...mapGetters({
        user: 'user',
        innerHeight: 'innerHeight',
      }),
      memberCardBackgroundColor() {
        return this.$vuetify.theme.dark ? '#121212' : '#f5f5f5'
      },
      rootHeight() {
        return this.innerHeight - 155
      },
    },
  }
</script>

<style>
  .member-controllers-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }
  .v-input__control {
    flex-direction: row !important;
    flex-wrap: nowrap !important;
  }
  .v-text-field__details {
    display: none !important;
  }
  .v-input__slot {
    margin: auto !important;
  }
</style>