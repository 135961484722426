<template>
  <v-tabs
    v-model="tab"
    class="px-2"
  >
    <v-tab
      @click="() => handlers[0]()"
      class="font-weight-bold text-caption"
    >
      <v-badge
        :color="allCounter ? 'primary' : 'transparent'"
        :content="allCounter"
      >
        {{ $t('community.member.filter.all') }}
      </v-badge>
    </v-tab>
    <v-tab
      @click="() => handlers[1]()"
      class="font-weight-bold text-caption"
    >
      <v-badge
        :color="requestedCounter ? 'primary' : 'transparent'"
        :content="requestedCounter"
      >
        {{ $t('community.member.filter.requested') }}
      </v-badge>
    </v-tab>
    <v-tab
      @click="() => handlers[2]()"
      class="font-weight-bold text-caption"
    >
      <v-badge
        :color="invitedCounter ? 'primary' : 'transparent'"
        :content="invitedCounter"
      >
        {{ $t('community.member.filter.invited') }}
      </v-badge>
    </v-tab>
  </v-tabs>
</template>

<script>
  import MemberService from '@api/member'
  import { mapGetters } from 'vuex'

  export default {
    props: {
      filterByField: Function
    },

    async created() {
      this.memberService = MemberService(this.user.id)
      const [all, requested, invited] = await Promise.all([
        this.memberService.count({
          community: this.$route.params.id,
          status: 'active'
        }),
        this.memberService.count({
          community: this.$route.params.id,
          status: 'requested'
        }),
        this.memberService.count({
          community: this.$route.params.id,
          status: 'invited'
        })
      ])
      this.allCounter = all
      this.requestedCounter = requested
      this.invitedCounter = invited
    },

    data() {
      return {
        tab: 0,
        handlers: [
          () => this.filterByField('status', ['active']),
          () => this.filterByField('status', ['requested']),
          () => this.filterByField('status', ['invited'])
        ],
        memberService: null,
        allCounter: 0,
        requestedCounter: 0,
        invitedCounter: 0
      }
    },

    computed: {
      ...mapGetters({
        user: 'user'
      })
    },

    methods: {
      setTab(tab) {
        if (this.tab === tab) return
        this.tab = tab
        this.items[tab]()
      }
    }
  }
</script>