<template>
  <v-menu
    offset-y
    rounded="lg"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        icon
        v-on="on"
      >
        <v-icon>
          {{ mdiDotsVertical }}
        </v-icon>
      </v-btn>
    </template>

    <v-list class="pa-0">
      <v-list-item
        v-for="(item, index) in menuItems" 
        :key="index"
        @click="item.handler"
      >
        <div v-if="item.subItems">
          <v-menu
            offset-y
            rounded="lg"
          >
            <template v-slot:activator="{ on }">
              <div
                v-on="on"
                class="d-flex clickable"
              >
                <v-list-item-action>
                  <v-icon>
                    {{ item.icon }}
                  </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </div>
            </template>
            <v-list class="pa-0">
              <v-list-item
                v-for="(subItem, subIndex) in item.subItems" 
                :key="subIndex"
                @click="subItem.handler"
              >
                <v-list-item-action v-if="subItem.icon">
                  <v-icon>
                    {{ subItem.icon }}
                  </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ subItem.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <div
          v-else
          class="d-flex"
        >
          <v-list-item-action>
            <v-icon>
              {{ item.icon }}
            </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              {{ item.title }}
            </v-list-item-title>
          </v-list-item-content>
        </div>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
  import MemberService from '@api/member'
  import { mapGetters } from 'vuex'
  import {
    mdiDotsVertical,
    mdiShieldHalfFull,
    mdiThumbDown,
    mdiThumbUp
  } from '@mdi/js'

  export default {
    props: {
      setMemberRole: Function,
      activateMember: Function,
      removeMember: Function,
      member: Object,
      userMember: Object
    },

    created() {
      this.memberService = MemberService(this.user.id)
    },

    data() {
      return {
        mdiDotsVertical,
        memberService: null,
        accepting: false,
        declining: false,
        rolesAllowedToEdit: {
          owner: ['owner', 'admin', 'moderator', 'member'],
          admin: ['admin', 'moderator', 'member'],
          moderator: ['moderator', 'member'],
          member: []
        },
      }
    },

    computed: {
      ...mapGetters({
        user: 'user'
      }),
      showRequestOptions() {
        return ['owner', 'admin'].includes(this.userMember.role) && this.member.status === 'requested'
      },
      menuItems() {
        return [{
          title: this.$t('community.member.permission'),
          icon: mdiShieldHalfFull,
          subItems: [
            ...(
              this.isRoleAllowed('owner') ? [
                {
                  title: this.$t('community.permissions.owner'),
                  handler: () => this.onPermissionChange('owner')
                }
              ] : []
            ),
            ...(
              this.isRoleAllowed('admin') ? [
                {
                  title: this.$t('community.permissions.admin'),
                  handler: () => this.onPermissionChange('admin')
                }
              ] : []
            ),
            ...(
              this.isRoleAllowed('moderator') ? [
                {
                  title: this.$t('community.permissions.moderator'),
                  handler: () => this.onPermissionChange('moderator')
                }
              ] : []
            ), {
              title: this.$t('community.permissions.member'),
              handler: () => this.onPermissionChange('member')
            }]
        },
        ...(
            this.showRequestOptions ? [{
              title: this.$t('community.member.accept'),
              icon: mdiThumbUp,
              handler: this.onAccept
            }, {
              title: this.$t('community.member.decline'),
              icon: mdiThumbDown,
              handler: this.onDecline
            }] : []
          )
        ]
      }
    },

    methods: {
      async onAccept() {
        this.accepting = true
        await this.memberService.acceptCommunityRequest({memberId: this.member._id})
        this.accepting = false
        this.activateMember(this.member._id)
      },
      async onDecline() {
        this.declining = true
        await this.memberService.declineCommunityRequest({memberId: this.member._id})
        this.declining = false
        this.removeMember(this.member._id)
      },
      async onPermissionChange(role) {
        await this.memberService.update(this.member._id, { role })
        this.setMemberRole(this.member._id, role)
      },
      isRoleAllowed(role) {
        if (
          !this.rolesAllowedToEdit ||
          !this.rolesAllowedToEdit[this.userMember.role]
        ) return;

        return this.rolesAllowedToEdit[this.userMember.role].includes(role);
      }
    }
  }
</script>