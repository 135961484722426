<template>
  <div class="pr-3">
    <v-card
      elevation="0"
      rounded="xl"
    >
      <v-container>
        <v-row
          v-if="isModeratorOrUp"
          justify="center"
        >
          <FilterTab :filterByField="filterByField" />
        </v-row>
        <v-row justify="center">
          <Members
            :membership="member"
            :members="members"
            :removeMember="removeMember"
            :activateMember="activateMember"
            :setMemberRole="setMemberRole"
          />
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import Members from '@components/Community/Member/MemberList'
  import MemberService from '@api/member'
  import FilterTab from '@components/Community/Member/FilterTab'

  export default {
    components: {
      Members,
      FilterTab
    },

    data() {
      return {
        memberService: null,
        member: {},
        rawMembers: [],
        members: []
      }
    },

    watch: {
      members: {
        handler() {
          if (this.$route.query.profileIdToAccept) {
            this.filterByField('status', ['requested'])
            this.$router.replace({ query: { profileIdToAccept: undefined } })
          }
        }
      }
    },

    created() {
      this.memberService = MemberService(this.user.id)
      this.loadUserMember()
      this.loadMembers()
    },

    computed: {
      ...mapGetters({
        user: 'user',
        profileId: 'profile/id',
        isModeratorOrUp: 'community/isModeratorOrUp',
      }),
    },

    methods: {
      activateMember(memberId) {
        this.members.find(member => member._id === memberId).status = 'active'
      },
      removeMember(memberId) {
        this.members = this.members.filter(member => member._id !== memberId)
      },
      setMemberRole(memberId, role) {
        this.members.find(member => member._id === memberId).role = role
      },
      async loadUserMember() {
        this.member = await this.memberService.getCommunityMembership(
          this.$route.params.id,
          this.profileId
        )
      },

      filterByField(field, values) {
        if (!field) return this.members = this.rawMembers
        this.members = this.rawMembers.filter(member => values.includes(member[field]))
      },

      async loadMembers() {
        this.rawMembers = await this.memberService.listByCommunity(
          this.$route.params.id
        )
        // The first tab should list only active members
        this.members = this.rawMembers.filter(
          member => member.status === 'active'
        )
      },
    }
  }
</script>